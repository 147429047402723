/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import PropTypes from "prop-types";

import Header from "./Header";
import Footer from "./Footer";
import { useSiteMetadata } from "../hooks/useSiteMetadata";

import 'normalize.css/normalize.css'; // reset your browser to start your styling a-fresh
import '../styles/style.scss';
import '../styles/components/_layout.scss';

const Layout = ({ children }) => {
  const siteMetadata = useSiteMetadata();

  return (
    <>
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 960,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
	  	<Header siteTitle={siteMetadata?.title || `Title`} />
        <main className="main">{children}</main>
        <Footer />
      </div>
    </>
);
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
