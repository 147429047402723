import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/Layout"
import Seo from "../../components/Seo";

const CatalogItemPage = () => (
	<Layout>
		<Seo title="Nostargic" />

		<h2>Nostargic</h2>

		<div className="main--centerwrapper">
			<StaticImage
			src="../../images/catalog artwork/JRIVEST11 nostargic front.jpg"
			alt="Jesse Rivest - Nostargic - cover art"
			placeholder="blurred"
			layout="constrained"
			className="main--centered"
			imgClassName="img--bordered-small"
			width={500}
			/>
		</div>

		<h3>Prerelease note</h3>
		<p>
			To be released on June 2, 2022.
		</p>

		<h3>Words from Jesse</h3>
		<p>
			I wrote this song in 2021 as part of my daily songwriting exercises;
			it appears that I first started it on October 19, to be exact.
			I called the idea <span className="italic">folk minor borrow</span>,
			probably because of the chords in the harmony that were borrowed from the parallel minor;
			the idea was in C major but had several chords from C minor acting as subdominants and dominants.
			The lyrics started to form after I had the musical idea.
			For years and years I wanted to write a song that combined starlight and nostalgia;
			I rememember the first time I drew a parallel between the two was during a car ride in New Zealand with Natalie Finnigan,
			who at the time was singing with me in Wellington.
			I don't recall if it was our conversation or if my mind simply wandered,
			but I felt really inspired when I imagined that the stars were burnt out and their light was still reaching us.
			Memories continue to reach us long after their source moments have passed; starlight takes eons to reach us.
			It wasn't until after I had sent the mastered track to the distributor that I searched online and confirmed that most of the stars we see are still there;
			however, it is indisputable that most of them are far enough away to have their light continue to reach us for a long time after they've gone.
		</p>
		<p>
			As a kid, I was fascinated with campfires and I especially loved waking up early and rekindling a flame from the embers of the night before.
			I would do this by gathering small kindling or leaves and then by blowing and blowing and blowing until... poof!
			A new flame. I loved this when I was a kid.
			I'm not sure how exactly, but this idea made its way into the song, too.
			I guess blowing at the embers is like trying to keep a memory alive—longer, brighter, stronger.
			I am a nostalgic person, but I was more so when I was younger.
			I would stare at the sky and feel a bittersweetness that was undeniably nostalgia.
			Today, I'm almost too busy to be nostalgic!
			But it's still in me, and I owe to it for a great deal of my songwriting.
		</p>
		<p>
			Checking my files, it appears I returned to the song early March 2022 and began producing it.
			I got more into it when I laid down the electric guitar—I felt I had captured a nice tone with my
			electric guitar and amp via a ribbon mic and a small-diaghram condenser mic.
			The vocal take probably came a little later.
			Later yet, I needed some real drums but realized that I couldn't afford them—a decent (sounding) studio
			costs money, as does a great drummer!
			So I decided to try out my fingers on the pads of my controller, using Addictive Drums 2,
			and although at first I was a little bit in doubt, it became clear that the drums were finished.
			I didn't quantize them, so the drums have my real human feel behind them.
			Of course it took many takes and—as often is the case with a real drummer—I had to nudge a few hits here and there.
		</p>
		<p>
			Bass, on the other hand, did not require a great-sounding studio and I was very much keen to involve my friend Murilo.
			Murilo gave it a spin at home and then a week later came by to plug in directly to one of my preamps.
			We were done recording him in less than an hour; I later put his performance through my usual emulation chain
			of a bass amp and speaker.
		</p>
		<p>
			That was pretty much it! I added some Hammond B3 on my own using my keyboard controller and an emulation synth I have.
			And the backing vocals were a quick addition as well at my studio.
			I decided to take a leap with this song—really for the first time in my life—and invest extra in divulging my work.
			I hired Auteur Research in Canada to help me push <span className="italic">Nostargic</span> out to the masses.
			So, if you happen to have heard the song on a playlist somewhere, or if you've read about it on a blog,
			it's because of Auteur.
		</p>
		<p>
			I hope you enjoy <span className="italic">Nostargic</span>; please share it if you do!
		</p>

		<h3>Track listing</h3>
		<ol>
			<li>
				Nostargic (4:07)<br />
				T3110485757 - © Jesse Rivest 2022 (SOCAN/BMI, CMRRA)<br />
				CA-6P8-22-00003 - © Jesse Rivest 2022 (PPL)
			</li>
		</ol>
		<p>
			This recording: catalog number JRIVEST11, © Jesse Rivest 2022, All Rights Reserved<br />
			UPC/EAN: 0198001761058<br />
			Released: June 2, 2022
		</p>

		<h3>Credits</h3>
		<p>
			Produced, recorded, mixed, and mastered by Jesse Rivest at his Little Bakery Studio in Brasilia, DF, Brazil.<br />
			Song written and sung by Jesse Rivest.<br />
			Drums performed by Jesse's fingers using pads and Addictive Drums 2.<br />
			Electric bass performed by Murilo Fabrino.<br />
			Electric guitar performed by Jesse Rivest.<br />
			Backing vocals performed by Jesse Rivest.<br />
			Album cover photography by Jesse Rivest.<br />
			Album cover layout by Jesse Rivest.<br />
		</p>

		<h3>Special Thanks</h3>
		<p>
			Thanks to Jane Lino for being supportive, always.<br />
			Thanks to Murilo for making the time to find and perform the bass line.<br />
			Thanks to Tico de Moraes for his ears and feedback.<br />
			Thanks to Gustavo Halfeld for his ears and feedback, and for running the vocal through his hardware FET compressor.
		</p>

		<h3>Extras</h3>

		<h4>Back cover</h4>
		<div className="main--centerwrapper">
			<StaticImage
			src="../../images/catalog artwork/JRIVEST11 nostargic back.png"
			alt="Jesse Rivest - Nostargic - back cover"
			placeholder="blurred"
			layout="constrained"
			className="main--centered"
			imgClassName="img--bordered-small"
			width={500}
			/>
		</div>

		<h4>Lyric video</h4>
		<div className="main--centerwrapper">
			<iframe
			className="main--centered"
			width="94%"
			height="315"
			style={{maxWidth: 560}}
			src="//www.youtube-nocookie.com/embed/42T4noJki_A"
			title="YouTube video player"
			frameBorder="0"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowFullScreen />
		</div>

		<h3 className="lyrics">Lyrics</h3>
		<div>
			<h4 className="lyrics__title">
				Nostargic</h4>
			<h5 className="lyrics__copyright">
				© Jesse Rivest, 2022
			</h5>
			<p>
				Out over the wing past the port-side light<br />
				My thoughts dive, pitch, roll, and yaw
			</p>
			<p>
				Ten km below, our world's hidden from sight<br />
				Here we hang, I'm suspended in awe
			</p>
			<p>
				Piercing through the black, trickle stories and songs<br />
				Truths so old, we cannot comprehend
			</p>
			<p>
				They propagate out, ever forward and strong<br />
				Though their sources have met their ends
			</p>
			<p>
				Once a strong and brilliant light<br />
				Glowing in your eyes<br />
				What remains I see the odd night<br />
				Twinkling in the sky
			</p>
			<p>
				I stood in a field on a new moon night<br />
				Felt your hand run through my hair
			</p>
			<p>
				Could I be made out under heavenly light<br />
				Were you reaching to me from somewhere?
			</p>
			<p>
				Whispers of old times on the voice of a breeze<br />
				Getting lost in a familiar scene
			</p>
			<p>
				The lights that linger above mountains and trees<br />
				Are the guiding lights of my dreams
			</p>
			<p>
				Once a strong and brilliant light<br />
				Universe in your eye<br />
				I go back in time the odd night<br />
				Blinking at the sky
			</p>
			<p>
				Once it was so crystal and stark<br />
				How our joy ran rife<br />
				If I could I'd find the old spark<br />
				Blow it back to life<br />
				Blow it back to life
			</p>
			<p>
				Blow it back to life<br />
				Blow it back to life<br />
				Blow it back to life
			</p>
		</div>

		<p>
			<Link to="../">Go back to Music Catalog</Link>
		</p>
	</Layout>
);

export default CatalogItemPage;
